
import { Component, Prop, Vue } from "vue-property-decorator";
import { ElUploadInternalRawFile } from "element-ui/types/upload";
import api from "@/api";
import Base64 from "@/utils/Base64";
import {AttachmentHostType} from "@/api/appService";
import {UserModule} from "@/store/modules/user";
import {AppModule} from "@/store/modules/app";

export interface IUploadObject {
  hasSuccess: boolean;
  uid: number;
  url: string;
  width: number;
  height: number;
}

@Component({
  name: "EditorImageUpload"
})
export default class extends Vue {
  @Prop({ required: true }) private color!: string;

  private dialogVisible = false;
  private listObj: { [key: string]: IUploadObject } = {};
  private defaultFileList = [];

  private checkAllSuccess() {
    return Object.keys(this.listObj).every(
      item => this.listObj[item].hasSuccess
    );
  }

  get headers(){
    return {
      'Abp.OrganizationUnitId':UserModule.getOU?.id,
      'Abp.TenantId':AppModule.getTenantId,
      'Authorization':`Bearer ${UserModule.getToken}`,
      '.AspNetCore.Culture':'c=zh-Hans|uic=zh-Hans'
    }
  }

  private handleSubmit() {
    const arr = Object.keys(this.listObj).map(v => this.listObj[v]);
    if (!this.checkAllSuccess()) {
      this.$message(
        "Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!"
      );
      return;
    }
    this.$emit("successCBK", arr);
    this.listObj = {};
    this.defaultFileList = [];
    this.dialogVisible = false;
  }

  private handleSuccess(res: any, file: ElUploadInternalRawFile) {
    if (res.success) {
      const fileName = res.result.url +'!w500';

      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = fileName;
          this.listObj[objKeyArr[i]].hasSuccess = true;
          return;
        }
      }
    }
  }

  private handleRemove(file: ElUploadInternalRawFile) {
    const uid = file.uid;
    const objKeyArr = Object.keys(this.listObj);
    for (let i = 0, len = objKeyArr.length; i < len; i++) {
      if (this.listObj[objKeyArr[i]].uid === uid) {
        delete this.listObj[objKeyArr[i]];
        return;
      }
    }
  }

  get uploadData(){
    return {hostType:AttachmentHostType.EditorImage};
  }

}
